import React from "react";
import {Button} from "@cuatroochenta/co-admin-react-library";

interface CreateBackupViewProps {
    onClick: () => void,

}

export const CreateBackupView = (
    {
        onClick,
    }: CreateBackupViewProps
): React.ReactElement => {
    return <Button content={'Exportar'} onClick={onClick}/>;
}