import React from 'react';
import ImportMobilisConfigurationContainer from './ImportMobilisConfigurationContainer';

class ImportMobilisConfigurationScreen extends React.Component {

    public render() {
        return <ImportMobilisConfigurationContainer />
    }

}

export default ImportMobilisConfigurationScreen;