import Urls from "./Urls";
import {AuthManager} from "@cuatroochenta/co-admin-react-library";

export default class TaskGetPendingTickets {

    private getGetInfo() {
        return {
                method: 'GET',
                headers: {
                'Authorization': 'Bearer ' + AuthManager.getAuthToken()
                }
            }
    }

    public get(page: number) {
        return fetch(Urls.URL_GET_PENDING_TICKETS.replace(':page', page.toString()), this.getGetInfo())
            .then(response => response.json())
            .then(data => {return data});
    }
}