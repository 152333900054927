import React from 'react';
import PendingTicketDataContainer from './PendingTicketDataContainer';

class PendingTicketScreen extends React.Component {
    
    public render() {
        return <PendingTicketDataContainer />
    }

}

export default PendingTicketScreen;