import React from "react";
import TaskCreateBackup from "../../ws/TaskCreateBackup";
import {CreateBackupView} from "./CreateBackupView";

export const CreateBackupContainer = (): React.ReactElement => {

    const onClick = () => {
        new TaskCreateBackup().get()
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.href = url;
                a.download = "backup.json";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    return <CreateBackupView onClick={onClick}/>;
}