import React from 'react';
import {PendingTicketResult} from '../../models/PendingTicket';
import TaskDeleteTicket from '../../ws/TaskDeleteTicket';
import TaskGetPendingTickets from '../../ws/TaskGetPendingTickets';
import TaskProcessTicket from '../../ws/TaskProcessTicket';
import PendingTicketView from './PendingTicketView';

export interface TicketDataResponse {
    success: boolean,
    error: string,
    message: string
}

class PendingTicketDataContainer extends React.Component {

    public state = {
        pendingTickets: {} as PendingTicketResult,
        page: 1,
        waiting: false,
        response: null as TicketDataResponse|null
    };

    public componentWillMount() {
        new TaskGetPendingTickets().get(this.state.page)
            .then(pendingTickets => {
                    this.setState({pendingTickets: pendingTickets});
                }
            )
    }

    public updateTickets(page: number) {
        new TaskGetPendingTickets().get(page)
        .then(pendingTickets => {
                this.setState({pendingTickets: pendingTickets});
            }
        )
    }

    public processTicket(id: string) {
        if (!this.state.waiting) {
            this.setWaiting(true);
            new TaskProcessTicket().get(id)
                .then((r) => this.onResponse(r))
        }
    }

    private onResponse(response: TicketDataResponse) {
        if (response.success) {
            new TaskGetPendingTickets().get(this.state.page)
                .then(pendingTickets => {
                        this.setState({pendingTickets: pendingTickets});
                        this.setState({response: response});
                        this.setWaiting(false);
                    }
                )
        } else {
            this.setState({response: response});
            this.setWaiting(false);
        }
    }

    public deleteTicket(id: string) {
        if (!this.state.waiting) {
            this.setWaiting(true);
            new TaskDeleteTicket().get(id)
                .then((r) => this.onResponse(r))
        }
    }

    private setWaiting(wait: boolean) {
        this.setState({waiting: wait});
    }

    public onChangePage(page: number) {
        this.setState({page: page})
        this.updateTickets(page)
    }

    public render() {
        return <PendingTicketView
            pendingTickets={this.state.pendingTickets}
            page={this.state.page}
            processTicket={this.processTicket.bind(this)}
            deleteTicket={this.deleteTicket.bind(this)}
            onChangePage={this.onChangePage.bind(this)}
            response={this.state.response}
            waiting={this.state.waiting}/>
    }

}

export default PendingTicketDataContainer;