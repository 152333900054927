import Urls from "./Urls";
import {AuthManager} from "@cuatroochenta/co-admin-react-library";

export default class TaskDeleteTicket {

    private getPostInfo() {
        return {
                method: 'POST',
                headers: {
                'Authorization': 'Bearer ' + AuthManager.getAuthToken()
                }
            }
    }

    public get(id: string) {
        return fetch(Urls.URL_DELETE_TICKET.replace(':oid', id), this.getPostInfo())
            .then(response => response.json())
            .then(data => {return data});
    }
}