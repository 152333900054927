const BASE_URL = process.env.REACT_APP_URL + navigator.language.slice(0,2) + '/api/';
const BASE_URL_PRIVATE = BASE_URL + 'private/';

export default class {
    public static URL_GET_COMPANIES = `${BASE_URL_PRIVATE}company/list/1`;
    public static URL_GET_TRANSPORT_NET = `${BASE_URL_PRIVATE}transportnet/list/1`;
    public static URL_GENERATE_QR = `${BASE_URL_PRIVATE}generate-encrypted-ticket`;
    public static URL_OBTAIN_FILE_SAP = `${BASE_URL_PRIVATE}obtener-fichero-sap`;
    public static URL_GET_PENDING_TICKETS = `${BASE_URL_PRIVATE}tickets-pendientes-tramitar/:page`;
    public static URL_PROCESS_TICKET = `${BASE_URL_PRIVATE}tramitar-ticket/:oid`;
    public static URL_DELETE_TICKET = `${BASE_URL_PRIVATE}delete-ticket/:oid`;
    public static URL_IMPORT_GTFS = `${BASE_URL_PRIVATE}import-gtfs`;
    public static URL_CREATE_BACKUP = `${BASE_URL_PRIVATE}backup`
    public static URL_EXPORT_MOBILIS_CONFIGURATION = `${BASE_URL_PRIVATE}mobilis-configuration/export`
    public static URL_IMPORT_MOBILIS_CONFIGURATION = `${BASE_URL_PRIVATE}mobilis-configuration/import`
}