import Urls from "./Urls";
import {AuthManager} from "@cuatroochenta/co-admin-react-library";

export default class TaskGenerateQR {

    private getPostInfo(company: string, transportnet: string, idBus: string) {
        return {
                method: 'POST',
                headers: {
                'Authorization': 'Bearer ' + AuthManager.getAuthToken()
                },
                body: JSON.stringify(
                    {
                        company_id: company,
                        transport_net_id: transportnet,
                        bus_id: idBus
                    }
                )
            }
    }

    public get(company: string, transportnet: string, idBus: string) {
        return fetch(Urls.URL_GENERATE_QR, this.getPostInfo(company, transportnet, idBus))
            .then(response => response.json())
            .then(data => {return data});
    }
}