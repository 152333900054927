import React from 'react';
import ImportGTFSView from "./ImportGTFSView";
import {TransportNet} from "../../models/TransportNet";
import TaskGetTransportNetsList from "../../ws/TaskGetTransportNet";
import TaskImportGTFS from "../../ws/TaskImportGTFS";

export interface ResponseGTFS {
    success: boolean
    message: string | null
    error: string | null
}

interface state {
    transportNets: TransportNet[]
    file: File | null
    response: ResponseGTFS | null
}

class ImportGTFSContainer extends React.Component {

    public state: state = {transportNets: [] as TransportNet[], file: null, response: null};

    public componentWillMount() {
        new TaskGetTransportNetsList().get()
            .then(transportNets => this.setState({transportNets: transportNets}))
    }

    public importGTFS(transportNet: string, file: File, stopLoading: () => void) {
        new TaskImportGTFS().get(transportNet, file)
            .catch(
                (response) => {
                    this.setState({response: {success:false, error: response.message} as ResponseGTFS});
                }
            )
            .then(
                (response) => {
                    if (response !== undefined) {
                        this.setState({response: response});
                    }
                    stopLoading();
                }
            )
    }

    public render() {
        return <ImportGTFSView
            transportNets={this.state.transportNets}
            importGTFS={this.importGTFS.bind(this)}
            response={this.state.response}
        />;
    }

}

export default ImportGTFSContainer;