import React from 'react';
import ObtainFileSapDataContainer from './ObtainFileSapDataContainer';

class ObtainFileSapScreen extends React.Component {
    
    public render() {
        return <ObtainFileSapDataContainer />
    }

}

export default ObtainFileSapScreen;