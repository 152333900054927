import React from 'react';
import { Company } from '../../models/Company';
import TaskGetCompaniesList from '../../ws/TaskGetCompaniesList';
import TaskObtainFileSap from '../../ws/TaskObtainFileSap';
import ObtainFileSapView from './ObtainFileSapView';

class ObtainFileSapDataContainer extends React.Component {

    public state = { companies: [] as Company[] };

    public componentWillMount() {
        new TaskGetCompaniesList().get()
        .then(companies => this.setState({companies: companies}))
    }

    public obtainFileSap(from: string, to: string, company: string) {
        new TaskObtainFileSap().get(from, to, company)
        .then(transactions => {
            var url = window.URL.createObjectURL(transactions);
            var a = document.createElement('a');
            a.href = url;
            a.download = "transactions.txt";
            document.body.appendChild(a);
            a.click();    
            a.remove();
        })
    }
    
    public render() {
        return <ObtainFileSapView companies={this.state.companies} obtainFileSap={this.obtainFileSap.bind(this)} />
    }

}

export default ObtainFileSapDataContainer;