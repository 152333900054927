import Urls from "./Urls";
import {AuthManager} from "@cuatroochenta/co-admin-react-library";

export default class TaskObtainFileSap {

    private getPostInfo(from: string, to: string, company: string) {
        return {
                method: 'POST',
                headers: {
                'Authorization': 'Bearer ' + AuthManager.getAuthToken()
                },
                body: JSON.stringify(
                    {
                        company_id: company,
                        from: from,
                        to: to
                    }
                )
            }
    }

    public get(from: string, to: string, company: string) {
        return fetch(Urls.URL_OBTAIN_FILE_SAP, this.getPostInfo(from, to, company))
            .then(response => response.blob())
            .then(data => {return data});
    }
}