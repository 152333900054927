import Urls from "./Urls";
import {AuthManager} from "@cuatroochenta/co-admin-react-library";
import {METHOD} from "@cuatroochenta/co-generic-request";

export default class TaskCreateBackup {

    private getGetInfo() {
        return {
            method: METHOD.METHOD_POST,
            headers: {
                'Authorization': 'Bearer ' + AuthManager.getAuthToken()
            }
        }
    }

    public get() {
        return fetch(Urls.URL_CREATE_BACKUP, this.getGetInfo())
            .then(response => response.blob())
            .then(data => {return data});
    }

}