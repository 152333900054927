import { Paginator } from '@cuatroochenta/co-admin-react-library';
import React from 'react';
import { Link } from 'react-router-dom';
import { PendingTicket, PendingTicketResult } from '../../models/PendingTicket';
import { TicketDataResponse } from "./PendingTicketDataContainer";

interface PendingTicketViewProps {
    pendingTickets: PendingTicketResult,
    page: number,
    processTicket: (id: string) => void,
    deleteTicket: (id: string) => void,
    onChangePage: (page: number) => void,
    waiting: boolean,
    response: TicketDataResponse | null
}


class PendingTicketView extends React.Component<PendingTicketViewProps> {

    public renderHeader(): React.ReactNode {
        return (
            <thead className="text-primary">
                <tr>
                    <th>Usuario</th>
                    <th>Email</th>
                    <th>Ticket</th>
                    <th>Fecha compra ticket</th>
                    <th>Fecha caducidad ticket</th>
                    <th className="list-actions-column">Acciones</th>
                </tr>
            </thead>
        );
    }

    public renderContentTable(): React.ReactNode {
        const data = this.props.pendingTickets.results;
        return (
            <tbody>
                {
                    data.map((row, index) => {
                        return (
                            <tr key={index}>
                                <td><Link
                                    to={`/user/view/${row.user?.oid}`}>{row.user ? `${row.user.name} ${row.user.lastName} ${row.user.secondLastName}` : ''}</Link>
                                </td>
                                <td>{row.user?.email}</td>
                                <td><Link to={`/ticket/view/${row.ticket?.oid}`}>{row.ticket?.name}</Link></td>
                                <td>{row.fechaCompra ? this.getDateAsString(row.fechaCompra) : ''}</td>
                                <td>{row.fechaCaducidad ? this.getDateAsString(row.fechaCaducidad) : ''}</td>
                                {this.renderActionsColumn(row)}
                            </tr>
                        )
                    })
                }

            </tbody>
        );
    }

    private getDateAsString(date: any): string {
        date = date.substring(0, 19);
        return date;
    }

    public renderActionsColumn(row: PendingTicket): React.ReactNode {
        return (
            <td className='list-actions-column'>
                <i className='fa fa-check-circle' onClick={() => this.props.processTicket(row.oid)} />
                <i className='fa fa-times-circle' onClick={() => this.props.deleteTicket(row.oid)} />
            </td>
        );

    }

    private renderMessageCard() {
        if (this.props.response === null || this.props.waiting) {
            return null;
        }

        if (this.props.response.success) {
            return this.renderMessage(this.props.response.message);
        }

        return this.renderMessage(this.props.response.error, { color: "red" });
    }

    private renderMessage(message: string | null, style: {} = {}) {
        return (
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <div className="message">
                            <label style={style}>{message}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public render(): React.ReactNode {

        if (!this.props.pendingTickets.results) {
            return <></>
        }

        if (Object.keys(this.props.pendingTickets.results).length > 0) {
            return (
                <div>
                    {this.renderMessageCard()}
                    <div className="col-md-12">
                        <div className="card shadow p-3">
                            <div className="card-body">
                                <div className={`table-responsive`}>
                                    <table className="table">
                                        {this.renderHeader()}
                                        {this.renderContentTable()}
                                    </table>
                                </div>
                                <Paginator
                                    currentPage={this.props.page}
                                    changePage={(page) => this.props.onChangePage(page)}
                                    primaryColor={'#51cbce'}
                                    pages={this.props.pendingTickets.pages}
                                    loading={this.props.waiting}
                                    numResults={this.props.pendingTickets.total}
                                    maxPerPage={50}
                                    changeMaxPerPage={(_, __) => {}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return <span>No se han encontrado resultados</span>


    }

}

export default PendingTicketView;