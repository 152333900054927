import React from "react";
import TaskExportMobilisConfiguration from "../../ws/TaskExportMobilisConfiguration";
import {ExportMobilisConfigurationView} from "./ExportMobilisConfigurationView";

export const ExportMobilisConfigurationContainer = (): React.ReactElement => {

    const onClick = () => {
        new TaskExportMobilisConfiguration().get()
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.href = url;
                a.download = "mobilis-configuration.json";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    return <ExportMobilisConfigurationView onClick={onClick}/>;
}