import React from 'react';
import {Company} from '../models/Company';
import {QRInfo} from '../models/QRInfo';
import {TransportNet} from '../models/TransportNet';
import {
    SelectorField,
    FieldFValue,
    InputTextField,
    Button,
    ButtonClasses
} from '@cuatroochenta/co-admin-react-library';

interface GenerateQrTicketViewProps {
    companies: Company[],
    transportnets: TransportNet[],
    qrinfo: QRInfo,
    generateQR: (company: string, transportnet: string, idBus: string) => void
}

class GenerateQrTicketView extends React.Component<GenerateQrTicketViewProps> {

    public state = {
        company: '',
        transportnet: '-1',
        showErrorTransportnet: false,
        showErrorCompany: false,
        showErrorIdBus: false,
        idBus: '',
    };

    requiredText = 'Por favor, rellena el campo';

    private renderCompanies(): React.ReactNode {
        const {
            companies,
        } = this.props;
        const {
            company,
            showErrorCompany,
        } = this.state;

        const values: FieldFValue[] = [{id: -1, description: ''}];
        companies.forEach(company => {
            values.push({id: company.oid, description: company.name, object: company})
        })

        return (
            <div className="form-group">
                <SelectorField
                    label={'Empresa*'}
                    value={company}
                    onChange={this.onChangeCompany}
                    disabled={false}
                    values={values}
                    helperText={showErrorCompany ? this.requiredText : ''}
                />
            </div>
        );
    }

    onChangeCompany = (value: string): void => {
        this.setState({
            company: value,
            showErrorCompany: !value || value.toString() === '-1',
        })
    }

    private renderTrasnportNets(): React.ReactNode {
        const values: FieldFValue[] = [{id: -1, description: ''}];
        this.props.transportnets.forEach(net => {
            values.push({id: net.oid, description: net.name, object: net});
        })

        return (
            <div className="form-group">
                <SelectorField
                    label={'Red de transporte*'}
                    value={this.state.transportnet}
                    onChange={this.onChangeTransportNet}
                    disabled={false}
                    values={values}
                    helperText={this.state.showErrorTransportnet ? this.requiredText : ''}
                />
            </div>
        );
    }

    onChangeTransportNet = (value: string): void => {
        this.setState({
            transportnet: value,
            showErrorTransportnet: !value || value.toString() === '-1',
        });
    }

    private renderIdBus() {
        const {
            idBus,
            showErrorIdBus,
        } = this.state;

        return (
            <div className={"form-group"}>
                <InputTextField
                    type={'text'}
                    size={10}
                    value={idBus}
                    onChange={this.onChangeIdBus}
                    disabled={false}
                    label={'Id bus*'}
                    helperText={showErrorIdBus ? this.requiredText : ''}
                />
            </div>
        )
    }

    onChangeIdBus = (value: string): void => {
        this.setState({
            idBus: value,
            showErrorIdBus: !value || value.toString() === '-1',
        });
    }

    private renderGenerateButton() {
        return (
            <Button
                type={'submit'}
                buttonClass={ButtonClasses.CUSTOM_BUTTON_FILLED}
                content={'Generar'}
            />
        )
    }

    generateQR(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault()
        this.props.generateQR(this.state.company, this.state.transportnet, this.state.idBus)
    }

    private renderQRInfo() {
        if (!this.props.qrinfo.route) {
            return <></>
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Plaincode</label>
                            <input type="text"
                                   className="form-control col-md-12"
                                   size={255}
                                   value={this.props.qrinfo.plain_code}
                                   disabled={false}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Encrypted code (base 64)</label>
                            <input type="text"
                                   className="form-control col-md-12"
                                   size={255}
                                   value={this.props.qrinfo.encrypted_code}
                                   disabled={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <img src={this.props.qrinfo.route} alt="Qr"/>
                    </div>
                </div>
            </div>
        )
    }

    public render() {
        return (
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <form onSubmit={(e) => this.generateQR(e)}>
                            {this.renderCompanies()}
                            {this.renderTrasnportNets()}
                            {this.renderIdBus()}
                            {this.renderGenerateButton()}
                        </form>
                        {this.renderQRInfo()}
                    </div>
                </div>
            </div>
        )
    }
}

export default GenerateQrTicketView;