import React from 'react';
import GenerateQrTicketDataContainer from './GenerateQrDataContainer';

class GenerateQrTicketScreen extends React.Component {
    
    public render() {
        return <GenerateQrTicketDataContainer />
    }

}

export default GenerateQrTicketScreen;