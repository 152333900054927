import React from 'react';
import {Company} from '../../models/Company';
import moment from "moment";
import {
    DateField,
    SelectorField,
    FieldFValue,
} from '@cuatroochenta/co-admin-react-library';
import {Button} from "@cuatroochenta/co-admin-react-library";

interface ObtainFileSapViewProps {
    companies: Company[],
    obtainFileSap: (from: string, to: string, company: string) => void
}

class ObtainFileSapView extends React.Component<ObtainFileSapViewProps> {

    public state = {company: '', dateStart: {} as Date, dateEnd: {} as Date};

    private renderCompanies(): React.ReactNode {
        let companies = [] as FieldFValue[];
        companies = this.props.companies.map((company) => ({id: company.oid, description: company.name, object: company}))

        return (
            <div className="row">
                <div className="col-md-6">
                    <SelectorField
                        label={'Empresa'}
                        value={this.state.company}
                        disabled={false}
                        onChange={(value: string) => this.onChangeCompany(value)}
                        values={companies}
                    />
                </div>
            </div>
        );
    }

    onChangeCompany(value: string): void {
        this.setState({company: value})
    }

    private renderDates() {
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <DateField
                            value={moment(this.state.dateStart).toDate().toDateString()}
                            type={'DATETIME'}
                            onChange={(date) => this.onChangeDate(date, true)}
                            name={'DateFileSapIni'}
                            label={'Fecha inicio'}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <DateField
                            value={moment(this.state.dateEnd).toDate().toDateString()}
                            type={'DATETIME'}
                            onChange={(date) => this.onChangeDate(date, true)}
                            name={'DateFileSapEnd'}
                            label={'Fecha fin'}
                        />
                    </div>
                </div>
            </div>
        )
    }

    private getDateAsString(date: any): string {
        const dateSelected = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        const hourSelected = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
        return dateSelected + ' ' + hourSelected;
    }

    public onChangeDate(date: any, start: boolean) {
        if (start) {
            this.setState({dateStart: date})
        } else {
            this.setState({dateEnd: date})
        }
    }

    private renderObtainButton() {
        return (
            <Button
                content={<><i className='fa fa-qrcode d-inline-block d-sm-none'/> Obtener</>}
                type="submit"
            />
        );
    }

    public obtainFile(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        this.props.obtainFileSap(this.getDateAsString(moment(this.state.dateStart).toDate()), this.getDateAsString(moment(this.state.dateEnd).toDate()), this.state.company)
    }

    public render() {
        return (
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <form onSubmit={(e) => this.obtainFile(e)}>
                            {this.renderDates()}
                            {this.renderCompanies()}
                            {this.renderObtainButton()}
                        </form>
                    </div>
                </div>
            </div>
        )
    }

}

export default ObtainFileSapView;
