import React from 'react';
import ImportGTFSContainer from './ImportGTFSContainer';

class ImportGTFSScreen extends React.Component {

    public render() {
        return <ImportGTFSContainer />
    }

}

export default ImportGTFSScreen;