import React from 'react';
import {FormRenderData, Module, Qr} from '@cuatroochenta/co-admin-react-library';

import '@cuatroochenta/co-admin-react-library/lib/index.css';
import './styles/custom.css';
import GenerateQrTicketScreen from './actions/modules/GenerateQrTicketScreen';
import ObtainFileSapScreen from './actions/modules/filesap/ObtainFileSapScreen';
import PendingTicketScreen from './actions/modules/pendingtickets/PendingTicketSreen';
import ImportGTFSScreen from "./actions/modules/importGTFS/ImportGTFSScreen";
import {CreateBackupScreen} from "./actions/modules/createBackups/CreateBackupScreen";
import {ExportMobilisConfigurationScreen} from "./actions/modules/exportMobilisConfiguration/ExportMobilisConfigurationScreen";
import ImportMobilisConfigurationScreen from "./actions/modules/importMobilisConfiguration/ImportMobilisConfigurationScreen";

interface Filters {
    [dynamic: string]: string
}

class App extends React.Component {

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        if (entity === 'linestop' && type === 'view') {
            const url = process.env.REACT_APP_QR_URL_BASE + data.fields.importationId;
            return <Qr url={url}/>;
        }
        return null;
    };

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private customButtonsList = (entity: string, filters: Filters, orderby: string, order: string): React.ReactNode => {
        return null;
    };

    private afterLoginButton = (): React.ReactNode => {
        return null;
    };

    public render() {
        const languages = [
            {name: 'Español', code: 'es'},
        ]

        return (
            <Module
                actions={[
                    {name: 'GenerateQrTicket', element: <GenerateQrTicketScreen/>},
                    {name: 'FicheroSap', element: <ObtainFileSapScreen/>},
                    {name: 'TicketsPendientesTramitar', element: <PendingTicketScreen/>},
                    {name: 'ImportarGTFS', element: <ImportGTFSScreen/>},
                    {name: 'createBackups', element: <CreateBackupScreen/>},
                    {name: 'exportMobilisConfiguration', element: <ExportMobilisConfigurationScreen/>},
                    {name: 'importMobilisConfiguration', element: <ImportMobilisConfigurationScreen/>}
                ]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
                locale={'es'}
                afterLoginButton={this.afterLoginButton}
                languages={languages}
            />
        );
    }

}

export default App;
