import React from 'react';
import {ResponseMobilisConfiguration} from "./ImportMobilisConfigurationContainer";
import {LoadingView, UploadField, Button, ButtonClasses} from "@cuatroochenta/co-admin-react-library";

interface ImportMobilisConfigurationViewProps {
    importMobilisConfiguration: (file: File, stopLoading: () => void) => void
    response: ResponseMobilisConfiguration | null
}

interface state {
    file: File | null
    loading: boolean
}

class ImportMobilisConfigurationView extends React.Component<ImportMobilisConfigurationViewProps> {

    public state: state = {file: null, loading: false};

    private renderFileSelector(): React.ReactNode {
        return (
            <div className="form-group">
                <div className="component-show-field-form">
                    <UploadField
                        value={this.state.file ? this.state.file.name : ''}
                        field={'file'}
                        type={''}
                        disabled={false}
                        taskUpload={(e: any) => this.onUploadFile(e.target.files ? e.target.files[0] : null)}
                        removeFile={() => this.setState({file: null})}
                        isEditScreen={true}
                        name={'file'}
                        label={"Selecciona un archivo"}
                    />
                </div>
            </div>
        );
    }

    public onUploadFile(file: File | null): void {
        this.setState({file: file});
    }


    importMobilisConfiguration(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault()
        if (this.state.file !== null && !this.state.loading) {
            this.setState({loading: true});
            this.props.importMobilisConfiguration(this.state.file, () => {
                this.setState({loading: false})
            })
        }
    }

    private renderGenerateButton() {
        return (
            <Button
                type={'submit'}
                buttonClass={ButtonClasses.CUSTOM_BUTTON_FILLED}
                content={'Importar Configuración Mobilis'}
            />
        )
    }

    private renderMessageCard() {
        if (this.props.response === null || this.state.loading) {
            return null;
        }

        if (this.props.response.success) {
            return this.renderMessage(this.props.response.message);
        }

        return this.renderMessage(this.props.response.error, {color: "red"});
    }

    private renderMessage(message: string | null, style: {} = {}) {
        return (
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <div className="message">
                            <label style={style}>{message}</label>
                        </div>
                    </div>
                </div>
            </div>
        )
            ;
    }

    public render() {

        if (this.state.loading) {
            return <LoadingView loading={this.state.loading}/>;
        }

        return (
            <div>
                {this.renderMessageCard()}
                <div className="col-md-12">
                    <div className="card shadow p-3">
                        <div className="card-body">
                            <form onSubmit={(e) => this.importMobilisConfiguration(e)}>
                                <div className={"row"}>
                                    {this.renderFileSelector()}
                                </div>
                                {this.renderGenerateButton()}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportMobilisConfigurationView;