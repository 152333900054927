import React from 'react';
import { Company } from '../models/Company';
import { QRInfo } from '../models/QRInfo';
import { TransportNet } from '../models/TransportNet';
import TaskGenerateQR from '../ws/TaskGenerateQR';
import TaskGetCompaniesList from '../ws/TaskGetCompaniesList';
import TaskGetTransportNetList from '../ws/TaskGetTransportNet';
import GenerateQrTicketView from './GenerateQrTicketView';

class GenerateQrTicketDataContainer extends React.Component {
    
    public state = { companies: [] as Company[], nets: [] as TransportNet[], qrinfo: {} as QRInfo };

    public componentWillMount() {
        new TaskGetCompaniesList().get()
        .then(companies => this.setState({companies: companies}))

        new TaskGetTransportNetList().get()
        .then(nets => this.setState({nets: nets}))
    }

    public generateQr(company: string, transportnet: string, idBus: string) {
        new TaskGenerateQR().get(company, transportnet, idBus)
        .then((qrinfo) => this.setState({qrinfo: qrinfo}))
    }

    public render() {
        return <GenerateQrTicketView 
            companies={this.state.companies}
            transportnets={this.state.nets}
            qrinfo={this.state.qrinfo}
            generateQR={this.generateQr.bind(this)}/>
    }

}

export default GenerateQrTicketDataContainer;